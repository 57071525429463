// Core
import { type KeyedMutator } from "swr";
import useSWRImmutable from "swr/immutable";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import HttpStatusCode from "http-status-codes";

// Definitions
import type { BasketProductsCountType } from "../types";
import type { HttpResponseAxiosDataType, HttpResponseAxiosErrorType } from "@/common/types/http";

// Hooks
import { useNotification } from "@/client/hooks/use-notification";

// Utils
import { basketApi } from "../infrastructure";
import { basketKeys } from "../query";
import { routes } from "@/client/definitions/book";
import { getHttpErrorMessage } from "@/client/services/notifications/notifications-msg";
import { log } from "@/client/utils/browser-logger";

type UseBasketProductsCountFetchType = {
  loadingBasketProductsCountFetch: boolean;
  basketProductsCount: number;
  mutateBasketProductsCount: (updateCountData: BasketProductsCountType) => void;
  revalidateBasketProductsCount: KeyedMutator<HttpResponseAxiosDataType<BasketProductsCountType>>;
};

export const fetcher = () => basketApi.basketProductsCountFetch();

export const useBasketProductsCountFetch = (): UseBasketProductsCountFetchType => {
  const { t } = useTranslation();
  const router = useRouter();
  const { showError } = useNotification();

  const { data, isLoading, mutate } = useSWRImmutable<
    HttpResponseAxiosDataType<BasketProductsCountType>,
    HttpResponseAxiosErrorType
  >(basketKeys.basketProductsCount, fetcher, {
    onError: (err: HttpResponseAxiosErrorType) => {
      try {
        const status = err.response.status;
        const isError400 = status === HttpStatusCode.BAD_REQUEST;
        const isError404 = status === HttpStatusCode.NOT_FOUND;
        const isError500 = status && status >= HttpStatusCode.INTERNAL_SERVER_ERROR;
        if (isError404) {
          return router.replace(routes.notFound);
        }
        if (isError400) {
          showError({
            title: err.response.data.type,
            desc: err.response.data.message,
          });
        }
        if (isError500) {
          showError(getHttpErrorMessage(t));
        }
      } catch (e) {
        showError(getHttpErrorMessage(t));
        log.crucial(err.message);
      }
    },
  });

  const mutateBasketProductsCount = (updateCountData: BasketProductsCountType) => {
    if (!data || !data.data || !updateCountData) {
      return null;
    }

    return void (async () => {
      const mutateDataBasketProductsCount = { ...data, data: { ...updateCountData } };
      await mutate(mutateDataBasketProductsCount, { revalidate: false });
    })();
  };

  return {
    loadingBasketProductsCountFetch: isLoading,
    basketProductsCount: data?.data?.count || 0,
    mutateBasketProductsCount,
    revalidateBasketProductsCount: mutate,
  };
};

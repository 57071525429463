export const basketKeys = {
  deliveryOptionsFetch: "basket-delivery-step-options-fetch",
  deliveryInformation: "basket-delivery-information-fetch",
  deliveryInformationOptions: "basket-delivery-information-options-fetch",
  deliveryInfo: "basket-delivery-info",
  paymentBankTransfer: "payment-bank-transfer",
  checkout: "basket-checkout",
  checkoutCreate: "basket-checkout-create",
  basketCreate: "basket-create",
  basketProducts: "basket/products",
  basketProductsCount: "basket/products/count",
  basketProductsUpdate: "basket-products-update",
  basketProductCreate: "/basket/add-product",
};

import(/* webpackMode: "eager", webpackExports: ["ConfigProvider","App"] */ "__barrel_optimize__?names=App,ConfigProvider!=!/builds/pro/wholesalers-frontend/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/pro/wholesalers-frontend/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/builds/pro/wholesalers-frontend/src/client/contexts/store-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/builds/pro/wholesalers-frontend/src/client/contexts/swr-provider.tsx");
;
import(/* webpackMode: "eager" */ "/builds/pro/wholesalers-frontend/src/client/theme/globals.css");

// Core
import cx from "classnames";

// Definitions
import type { BadgeProps as AntBadgeProps } from "antd";

// Components
import { Badge as AntBadge } from "antd";

// Utils
import st from "./styles.module.css";

export type BadgeProps = AntBadgeProps;
export const Badge = (props: AntBadgeProps) => {
  const badgeCss = cx(props.className, st.badge);
  return <AntBadge {...props} className={badgeCss} />;
};

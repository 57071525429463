// Definitions
import {
  type FormatPhoneOptions,
  PhoneNumberFormatType,
  PhoneNumberFormatter,
} from "./phone-number-formatter";
import { UserCountryCodes, UserCountryCodesType } from "@/client/domains/user/types";

const formatter = new PhoneNumberFormatter();
const phoneNumberFormats = formatter.getPhoneNumberFormats();

export const getFormatPhoneNumber = (
  phoneNumber: string,
  country: UserCountryCodesType = UserCountryCodes.fr,
): string => {
  const countriesFormat: Partial<Record<UserCountryCodesType, PhoneNumberFormatType>> = {
    gb: phoneNumberFormats.SPECIAL,
    fr: phoneNumberFormats.INTERNATIONAL,
    de: phoneNumberFormats.INTERNATIONAL,
    at: phoneNumberFormats.INTERNATIONAL,
  };

  const optionsFormatter: FormatPhoneOptions = {
    regionCode: country,
    formatPhone: countriesFormat[country],
  };

  return formatter.formatPhoneNumber(phoneNumber, optionsFormatter);
};

// Core
import HttpStatusCode from "http-status-codes";

// Definitions
import type { HttpResponseAxiosErrorType } from "@/common/types/http";
import { routeHandlers } from "@/client/definitions/book";

// Utils
import { log } from "@/client/utils/browser-logger";
import { verifyBrowser } from "@/client/utils/verify-browser";

export const apiErrorHandler = (error: HttpResponseAxiosErrorType) => {
  if (
    error.response?.status === HttpStatusCode.UNAUTHORIZED ||
    error.status === HttpStatusCode.UNAUTHORIZED
  ) {
    log.crucial("Error 401 by API request:", error);
    // 🔒 sign a user out
    const returnUrl = verifyBrowser() ? `${window.location.origin}${routeHandlers.signout}` : "";
    return (window.location.href = returnUrl);
  }
  return Promise.reject(error);
};

import { HttpRequestParams, HttpResponseApiDataType } from "@/common/types/http";
import {
  BasketDeliveryOptionsType,
  BasketDeliveryCreateParamsType,
  BasketDeliveryInformationType,
  BasketCheckoutType,
  BasketCheckoutCreateType,
  BasketPaymentBankTransferType,
  BasketDeliveryInformationOptionsType,
  BasketProductsTableType,
  BasketProductUpdateType,
  BasketCreateParamsType,
  BasketProductsCountType,
} from "./types";
import { http } from "@/client/transport/http";
import { ProductCartCreateParamsType } from "@/client/domains/search/products/types";

export const basketApi = Object.freeze({
  basketCreate: (payload: BasketCreateParamsType) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType<void>>("/basket/mass-search/add-products", data);
  },
  basketProductCreate: (payload: ProductCartCreateParamsType) => {
    const { data } = payload;
    return http.post<HttpResponseApiDataType>("/basket/add-product", data);
  },
  basketProductsFetch: () => {
    return http.get<HttpResponseApiDataType<BasketProductsTableType>>("/basket/products");
  },
  basketProductsCountFetch: () => {
    return http.get<HttpResponseApiDataType<BasketProductsCountType>>("/basket/products/count");
  },
  fetchCheckout: () => {
    return http.get<HttpResponseApiDataType<BasketCheckoutType>>("/basket/checkout");
  },
  createCheckout: () => {
    return http.post<HttpResponseApiDataType<BasketCheckoutCreateType>>("/basket/checkout");
  },
  fetchDeliveryOptions: () => {
    return http.get<HttpResponseApiDataType<BasketDeliveryOptionsType>>("/basket/delivery-options");
  },
  createDelivery: (payload: BasketDeliveryCreateParamsType) => {
    return http.post<HttpResponseApiDataType>("/basket/delivery-information", payload.data);
  },
  fetchDeliveryInformation: () => {
    return http.get<HttpResponseApiDataType<BasketDeliveryInformationType>>(
      "/basket/delivery-information",
    );
  },
  fetchDeliveryInformationOptions: () => {
    return http.get<HttpResponseApiDataType<BasketDeliveryInformationOptionsType>>(
      "/basket/delivery-information-options",
    );
  },
  fetchPaymentBankTransfer: () => {
    return http.get<HttpResponseApiDataType<BasketPaymentBankTransferType>>(
      "/basket/payment/bank-transfer",
    );
  },
  updateBasketProduct: (payload: HttpRequestParams<BasketProductUpdateType>) => {
    const { data, id } = payload;
    return http.patch<HttpResponseApiDataType<BasketProductsTableType>>(
      `/basket/products/${id}`,
      data,
    );
  },
  deleteBasketProduct: (payload: HttpRequestParams) => {
    return http.delete<HttpResponseApiDataType<BasketProductsTableType>>(
      `/basket/products/${payload.id}`,
    );
  },
});

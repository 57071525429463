// Definitions
import type { LightListItemType } from "@/client/domains/user/navigation/components/light-list/light-list";
import { BASKET_MAX_COUNT } from "@/client/definitions/constants";

// Components
import { Link, LinkProps } from "@/client/components/block-elements/link";
import { routes } from "@/client/definitions/book";
import { NavbarUserPopover } from "@/client/domains/user/navigation/components/navbar-user-popover";

export type ProfileActionRenderModelPropsType = {
  basketProductsCount: {
    count: number;
    loadingCount: boolean;
    className?: string;
  };
};

export const profileActionRenderModel = (
  props: ProfileActionRenderModelPropsType,
): LightListItemType[] => {
  const {
    basketProductsCount: { loadingCount, ...badgeProps },
  } = props;
  const CellLink = (linkProps: LinkProps) => Link({ ...linkProps, type: "default" });
  return [
    // toDo: wait for an implementation of the notifications system
    // {
    //   key: "1",
    //   icon: "Bell",
    // },
    {
      key: "2",
      icon: "UserProfile",
      link: {
        href: routes.settings,
        component: CellLink,
      },
      popover: {
        component: NavbarUserPopover,
        trigger: "hover",
        placement: "bottom",
      },
      itemTestId: "user-area-navigation-item",
    },
    {
      key: "3",
      icon: "Support",
    },
    {
      key: "4",
      icon: "Cart",
      loading: loadingCount,
      link: {
        href: routes.basket,
        component: CellLink,
      },
      badge: {
        ...badgeProps,
        overflowCount: BASKET_MAX_COUNT,
      },
    },
  ];
};

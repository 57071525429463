"use client";

// Core
import { useTranslation, Trans } from "react-i18next";
import { usePathname } from "next/navigation";
import cx from "classnames";

// Components
import { LayoutContainer } from "@/client/components/layouts/layout-container";
import { Row } from "@/client/components/ui/Row";
import { Col } from "@/client/components/ui/Col";
import { Title } from "@/client/components/ui/Typography/Title";
import { Text } from "@/client/components/ui/Typography/Text";
import { List } from "@/client/components/ui/List";
import { SigninRequest } from "@/client/domains/auth/signin/components/signin-request";
import { SignupRequest } from "@/client/domains/signup/signup-request/components/signup-request";

// Utils
import st from "./styles.module.css";
import { ROW_GUTTER_TYPE } from "@/client/components/ui/Row/utils";
import { routes } from "@/client/definitions/book";

type ProWholesalersPropsType = {
  top?: boolean;
};

export const ProWholesalers = ({ top }: ProWholesalersPropsType) => {
  const { t } = useTranslation("signup");
  const pathname = usePathname();
  const proWholeSalersStyles = cx(st["pro-wholesalers"], {
    [st.top]: top,
  });

  const benefits: string[] = t("signup:pro-wholesalers.benefits", { returnObjects: true });
  return (
    <section className={proWholeSalersStyles} data-testid="landing-pro-wholesalers">
      <LayoutContainer>
        <Row gutter={ROW_GUTTER_TYPE.md.horizontal}>
          <Col span={16}>
            <div className={st["pro-wholesalers__heading"]}>
              <Title tag={"h1"} color="white-100" size={"44"} font="second">
                <Trans
                  i18nKey="signup:pro-wholesalers.headline"
                  components={{
                    br: <br />,
                    orange: <Text font="second" size="44" color="orange-900" />,
                  }}
                />
              </Title>
            </div>
            <div className={st["pro-wholesalers__benefits"]}>
              <List
                dataSource={benefits}
                className={st["pro-wholesalers__benefits-list"]}
                renderItem={(item) => (
                  <List.Item className={st["pro-wholesalers__benefits-list_item"]}>
                    <Text color="main-gray-3" size="20">
                      {item}
                    </Text>
                  </List.Item>
                )}
              />
            </div>
          </Col>
          <Col span={8}>
            {pathname === routes.signin && <SigninRequest />}
            {pathname === routes.signup && (
              <SignupRequest formName={`signup-form-${top ? "main" : "bottom"}`} />
            )}
          </Col>
        </Row>
      </LayoutContainer>
    </section>
  );
};

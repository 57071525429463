// Core
import { forwardRef, ReactElement } from "react";
import cx from "classnames";

// Definitions
import type { CheckboxProps, CheckboxRef } from "antd";
import type { CheckboxChangeEvent, CheckboxChangeEventTarget } from "antd/es/checkbox/Checkbox";

// Components
import { Checkbox } from "antd";

// Utils
import st from "./styles.module.css";
import { ValueOf } from "@/common/types/basic";

const inputCheckboxColors = {
  base: "base",
  orange: "orange",
} as const;
const inputCheckboxAligns = {
  middle: "middle",
  top: "top",
  bottom: "bottom",
} as const;
export const inputCheckboxSizes = {
  small: "small",
  middle: "middle",
  large: "large",
} as const;
const inputCheckboxModes = {
  default: "default",
  indeterminate: "indeterminate",
} as const;
const inputCheckboxIndeterminateValues = {
  empty: "empty",
  all: "all",
  partially: "partially",
} as const;

const getValueModeIndeterminate = (value: string): boolean => {
  switch (value) {
    case inputCheckboxIndeterminateValues.all: {
      return true;
    }
    case inputCheckboxIndeterminateValues.empty:
    case inputCheckboxIndeterminateValues.partially: {
      return false;
    }
    default:
      return false;
  }
};

export interface IInputCheckboxProps extends CheckboxProps {
  children?: ReactElement;
  hasDisabled?: boolean;
  value?: boolean | string;
  mode?: ValueOf<typeof inputCheckboxModes>;
  size?: keyof typeof inputCheckboxSizes;
  color?: keyof typeof inputCheckboxColors;
  align?: keyof typeof inputCheckboxAligns;
  onChange?: (e: unknown) => void;
}

interface ICheckboxChangeEventTarget extends CheckboxChangeEventTarget {
  value?: string;
}
interface ICheckboxChangeEvent extends CheckboxChangeEvent {
  target: ICheckboxChangeEventTarget;
}

export const InputCheckbox = forwardRef<CheckboxRef | null, IInputCheckboxProps>((props, ref) => {
  const {
    className,
    hasDisabled = false,
    children,
    value,
    color = inputCheckboxColors.orange,
    size = inputCheckboxSizes.middle,
    mode = inputCheckboxModes.default,
    align = inputCheckboxAligns.middle,
    defaultChecked,
    ...rest
  } = props;

  const isModeIndeterminate = mode === inputCheckboxModes.indeterminate;
  const isModeIndeterminateStatus =
    value !== inputCheckboxIndeterminateValues.all &&
    value !== inputCheckboxIndeterminateValues.empty &&
    isModeIndeterminate;
  const checkedValue = defaultChecked
    ? true
    : isModeIndeterminate
      ? getValueModeIndeterminate(value as string)
      : Boolean(value);

  const handleChange = (evt: ICheckboxChangeEvent) => {
    if (isModeIndeterminate) {
      const checked = evt.target.checked;
      const inputValue = String(evt.target.value);
      if (checked) {
        if (inputValue === inputCheckboxIndeterminateValues.all) {
          return rest?.onChange?.(inputCheckboxIndeterminateValues.empty);
        }
        if (inputValue === inputCheckboxIndeterminateValues.partially) {
          return rest?.onChange?.(inputCheckboxIndeterminateValues.all);
        }
        if (inputValue === inputCheckboxIndeterminateValues.empty) {
          return rest?.onChange?.(inputCheckboxIndeterminateValues.all);
        }
      }
      if (inputValue === inputCheckboxIndeterminateValues.all) {
        return rest?.onChange?.(inputCheckboxIndeterminateValues.empty);
      }
      if (inputValue === inputCheckboxIndeterminateValues.partially) {
        return rest?.onChange?.(inputCheckboxIndeterminateValues.all);
      }
      if (inputValue === inputCheckboxIndeterminateValues.empty) {
        return rest?.onChange?.(inputCheckboxIndeterminateValues.all);
      }
      return;
    }
    rest.onChange?.(evt);
  };
  const checkboxStyle = cx(
    st.checkbox,
    {
      ...(inputCheckboxSizes[size] && {
        [st[`checkbox-size-${size}`]]: true,
      }),
      ...(inputCheckboxColors[color] && {
        [st[`checkbox-color-${color}`]]: true,
      }),
      ...(inputCheckboxAligns[align] && {
        [st[`checkbox-align-${align}`]]: true,
      }),
    },
    className,
  );

  return (
    <Checkbox
      {...rest}
      className={checkboxStyle}
      disabled={hasDisabled}
      ref={ref}
      indeterminate={isModeIndeterminateStatus}
      checked={checkedValue}
      value={value}
      onChange={handleChange}
    >
      {children}
    </Checkbox>
  );
});
InputCheckbox.displayName = "InputCheckbox";

// Components
import { LightList } from "src/client/domains/user/navigation/components/light-list";

// Hooks
import { useBasketProductsCountFetch } from "@/client/domains/basket/hooks/use-basket-products-count-fetch";

// Utils
import { profileActionRenderModel } from "./profile-action-render-model";
import st from "./styles.module.css";

export const NavbarProfile = () => {
  const { loadingBasketProductsCountFetch, basketProductsCount } = useBasketProductsCountFetch();
  const menuItems = profileActionRenderModel({
    basketProductsCount: {
      count: basketProductsCount,
      loadingCount: loadingBasketProductsCountFetch,
      className: st["navbar-profile-badge"],
    },
  });

  return (
    <LightList testId="navbar-profile-list" mode="horizontal" type="actions" items={menuItems} />
  );
};

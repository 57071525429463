// Core
import { TFunction } from "i18next";

// Definition
import { NotificationMessage } from "@/client/components/ui/Notification";

// Utils
import {
  httpErrorMessage,
  httpErrorMessageNotFound,
  httpErrorMessageNoData,
  httpSuccessMessageUpdate,
  httpSuccessMessage,
  httpErrorForbiddenMessage,
} from "./notifications-msg-static";

export const getHttpSuccessMessage = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.updateBase.title") || httpSuccessMessageUpdate.title,
  desc: t("common:notifications.updateBase.desc") || httpSuccessMessageUpdate.desc,
});
export const getHttpSuccessBasketAddMessage = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.basketAddSuccess.title") || httpSuccessMessage.title,
  desc: t("common:notifications.basketAddSuccess.desc") || httpSuccessMessage.desc,
});
export const getHttpSuccessBasketProductAddMessage = (
  t: TFunction<["common"]>,
): NotificationMessage => ({
  title: t("common:notifications.cardProductAddSuccess.title") || httpSuccessMessage.title,
  desc: t("common:notifications.cardProductAddSuccess.desc") || httpSuccessMessage.desc,
});
export const getHttpErrorMessage = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.errorBase.title") || httpErrorMessage.title,
  desc: t("common:notifications.errorBase.desc") || httpErrorMessage.desc,
});
export const getHttpErrorMessageNotFound = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.notFoundBase.title") || httpErrorMessageNotFound.title,
  desc: t("common:notifications.notFoundBase.desc") || httpErrorMessageNotFound.desc,
});
export const getHttpErrorMessageNoData = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.errorsNoDataBase.title") || httpErrorMessageNoData.title,
  desc: t("common:notifications.errorsNoDataBase.desc") || httpErrorMessageNoData.desc,
});
export const getHttpErrorForbiddenMessage = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.errorsForbidden.title") || httpErrorForbiddenMessage.title,
  desc: t("common:notifications.errorsForbidden.desc") || httpErrorForbiddenMessage.desc,
});
export const getHttpSuccessMessageCallBack = (
  t: TFunction<["common", "signup"]>,
): NotificationMessage => ({
  title: t("signup:callback.success.title") || httpSuccessMessage.title,
  desc: t("signup:callback.success.desc") || httpSuccessMessage.desc,
});
export const getHttpUploadErrorMessage = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.fileUploadError.title") || httpErrorMessage.title,
  desc: t("common:notifications.fileUploadError.desc") || httpErrorMessage.desc,
});
export const getHttpErrorMessageHashExpired = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.resetPassword.error.hashExpired.title") || httpErrorMessage.title,
  desc: t("common:notifications.resetPassword.error.hashExpired.desc") || httpErrorMessage.desc,
});
export const getHttpErrorMessageHashInvalid = (t: TFunction<["common"]>): NotificationMessage => ({
  title: t("common:notifications.resetPassword.error.hashInvalid.title") || httpErrorMessage.title,
  desc: t("common:notifications.resetPassword.error.hashInvalid.desc") || httpErrorMessage.desc,
});
